import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const LeftSideContent: React.FC = () => {
  return (
    <div className="left-side-content">
      <div className="logo">
        <img src="/logo.png" alt="MyApp Logo" />
      </div>
      <Title level={2} className="main-title">Bienvenidos a StoryTime</Title>
      <Paragraph className="description">
        Descubre la magia de aprender inglés a través de cuentos personalizados, donde la imaginación de tu hijo es la protagonista.
      </Paragraph>
      <ul className="features-list">
        <li>
          <Paragraph>
            <strong>Aventuras Personalizadas con IA:</strong> Crea historias únicas con tu hijo como protagonista y define desafíos y lecciones específicas para cada aventura.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Cuentos Interactivos:</strong> Disfruta de cuentos diseñados para mejorar la comprensión lectora y el vocabulario de tu hijo mientras se divierten.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Cuestionarios Inteligentes:</strong> Refuerza el aprendizaje con cuestionarios personalizados que ayudan a evaluar y mejorar la comprensión de lectura.
          </Paragraph>
        </li>
      </ul>
    </div>
  );
};

export default LeftSideContent;
