import React from 'react';
import { Layout, Row, Col } from 'antd';
import LeftSideContent from './home/LeftSideContent';
import SimpleStory from './story/SimpleStory';

const { Content } = Layout;

const Home: React.FC = () => {
  return (
    <Content className="content-wrapper">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <LeftSideContent />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {/* <SimpleStory storyId="anotherStoryId" /> */}
        </Col>
      </Row>
    </Content>
  );
};

export default Home;
