import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  return (
    <AntHeader className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']} style={{ flex: 1, justifyContent: 'center', background: '#4f4b5a' }}>
        <Menu.Item key="1">
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/about">About</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/contact">Contact</Link>
        </Menu.Item>
      </Menu>
      {/* <Button type="primary">
        <Link to="/login">Login</Link>
      </Button> */}
    </AntHeader>
  );
};

export default Header;
