import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter: React.FC = () => {
  return (
    <Footer style={{ textAlign: 'center', background: '#f0f2f5', padding: '10px' }}>
      © {new Date().getFullYear()} Your Company Name. All rights reserved.
    </Footer>
  );
};

export default AppFooter;