import { Layout } from 'antd';
import AppFooter from './components/AppFooter';
// import Students from './components/Students';
// import Tests from './components/Tests';
// import { StoryCreator } from './components/createNewStory/StoryCreator';
// import StudentProfilePage from './components/student/StudentProfilePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';
import Header from './components/Header';
import './App.css'

const { Content } = Layout;

function App() {
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Header />
        <Content style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/students" element={<Students />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/tests" element={<Tests />} />
              <Route path="/create-test-sheet" element={<StoryCreator />} />
              <Route path="/student-profile/:studentId" element={<StudentProfilePage />} />
            </Route> */}
          </Routes>
        </Content>
        <AppFooter />
      </Layout>
    </Router>
  );
}

export default App;
